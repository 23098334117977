<template>
  <v-container id="store" fluid tag="section">
    <v-data-table
      :headers="columns"
      :items="productStock"
      item-key="_id"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="6"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
    <v-row class="mt-3">
      <v-col cols="12" class="text-left">
        <router-link :to="{ name: 'Stores' }">
          <v-btn type="button" color="primary" class="mr-0">Back</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      productStock: [],
      search: "",
      loading: true,
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
    };
  },
  computed: {
    columns() {
      return [
        { sortable: false, text: "Product Name", value: "name" },
        { sortable: false, text: "Product Price", value: "price" },
        { sortable: false, text: "Product Description", value: "description" },
        { sortable: false, text: "Product Stock", value: "stock" },
      ];
    },
  },
  methods: {
    getProductStock() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "store/product/" +
            this.id +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.productStock = response.data.productStock.data;
            this.loading = false;
            this.totalPages = response.data.productStock.last_page;
            this.startRecord = response.data.productStock.from;
            this.endRecord = response.data.productStock.to;
            this.totalRecord = response.data.productStock.total;
            this.numbers = [];
            for (
              let num = response.data.productStock.from;
              num <= response.data.productStock.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    handlePageChange(value) {
      this.page = value;
      this.getProductStock();
    },
  },
  mounted() {
    this.getProductStock();
  },
};
</script>
